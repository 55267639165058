import {
  useCall,
  useFetchWithErrorTracking,
} from '@ee-monorepo/shared/utilities/hooks';
import {
  MaeplConfiguration,
  MaeplResponse,
  ObservationsInterface,
} from './types';

export function useGetResult(config: MaeplConfiguration) {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { call, data, loading, error } = useCall<MaeplResponse>(
    (observations: ObservationsInterface[]) =>
      fetchWithErrorTracking(
        `${process.env.NEXT_PUBLIC_MAEPL_URL}/get_result`,
        {
          method: 'PUT',
          headers: {
            'api-key': process.env.NEXT_PUBLIC_MAEPL_API_KEY || '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: config.uuid,
            project_id: config.project_id,
            save: true,
            simplify: true,
            observations,
          }),
          signal: AbortSignal.timeout(3000),
        }
      ),
    {
      cacheKey: `maepl/get_result/${config.uuid}/${config.project_id}}`,
    }
  );
  return {
    getTreatment: call,
    treatmentData: data,
    treatmentError: error,
    treatmentLoading: loading,
  };
}
