import {
  MaeplConfiguration,
  ObservationsInterface,
  useMaeplTreatments,
} from '@ee-monorepo/shared/data-access/maepl';
export function useSampleMultiExperiment(
  source: string,
  product: string,
  phoneNumber: string,
  applicationId: string
) {
  const config: MaeplConfiguration = {
    uuid: process.env.NEXT_PUBLIC_MAEPL_SAMPLE_MULTI_UUID || '',
    project_id: process.env.NEXT_PUBLIC_MAEPL_SAMPLE_MULTI_PROJECT_ID || '',
  };

  const observations: ObservationsInterface[] = [
    {
      id: phoneNumber,
      secondary_ids: {
        applicationId,
      },
      data: {
        source,
        product,
      },
    },
  ];
  const { treatments } = useMaeplTreatments(
    config,
    ['Approval Page', 'Confetti'],
    observations
  );

  return {
    showConfetti: treatments['Confetti'] === 'Confetti_On',
    showOldApprovalPage: treatments['Approval Page'] === 'Old Approval Page',
  };
}
