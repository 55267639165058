import cn from 'classnames';
import s from './hero.module.scss';
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { getStyle } from './hero-image.styles';

const HeroImage = ({
  variation,
  items,
  industryIndex,
  isPrequal,
  prequal_hero_image,
  is_center_image,
  hero_image,
}) => {
  const styles = getStyle(variation, { is_center_image });
  return (
    <>
      {/* hero image */}
      {variation === 'default-slice' ? (
        items.map((item, i) => (
          <div
            key={item.industry}
            className={cn(s.heroImageWrapper, s.animatedHeroImage, {
              [s.animatedHeroImageActive]: i === industryIndex,
            })}
          >
            <ImageWrapper
              className="hidden md:inline-block max-w-full h-auto"
              src={item.hero_image?.url}
              alt={item.hero_image?.alt}
              width={item.hero_image?.dimensions?.width}
              height={item.hero_image?.dimensions?.height}
              data-testid={i === industryIndex ? 'imageActive' : 'image'}
              priority
            />
            <ImageWrapper
              className="inline-block md:hidden"
              src={item.hero_image?.mobile?.url}
              alt={item.hero_image?.mobile?.alt}
              width={item.hero_image?.mobile?.dimensions?.width}
              height={item.hero_image?.mobile?.dimensions?.height}
              data-testid={
                i === industryIndex ? 'mobileImageActive' : 'mobileImage'
              }
              priority
            />
          </div>
        ))
      ) : isPrequal && variation === 'heroPrequal' ? (
        <ImageWrapper
          className={cn(
            'absolute',
            'hidden lg:inline-block',
            'right-0',
            'bottom-0'
          )}
          src={prequal_hero_image?.url}
          alt={prequal_hero_image?.alt}
          width={prequal_hero_image?.dimensions?.width}
          height={prequal_hero_image?.dimensions?.height}
          data-testid={'heroStaticImage'}
          priority
        />
      ) : (
        <div className={styles.heroStaticImageParent}>
          <ImageWrapper
            className="hidden lg:inline-block max-w-full h-auto"
            src={hero_image?.url}
            alt={hero_image?.alt}
            width={hero_image?.dimensions?.width}
            height={hero_image?.dimensions?.height}
            data-testid={'heroStaticImage'}
            priority
          />
          <ImageWrapper
            className="inline-block lg:hidden"
            src={hero_image?.mobile?.url}
            alt={hero_image?.mobile?.alt}
            width={hero_image?.mobile?.dimensions?.width}
            height={hero_image?.mobile?.dimensions?.height}
            data-testid={'heroStaticImageMobile'}
            priority
          />
        </div>
      )}
    </>
  );
};

export default HeroImage;
