import {
  useCall,
  useFetchWithErrorTracking,
} from '@ee-monorepo/shared/utilities/hooks';
import { AttributeInterface, MaeplConfiguration } from './types';

export function useAddAttribute(config: MaeplConfiguration) {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { call, data, loading, error } = useCall<{ status_code: number }>(
    (attributes: AttributeInterface[]) =>
      fetchWithErrorTracking(
        `${process.env.NEXT_PUBLIC_MAEPL_URL}/add_attribute`,
        {
          method: 'PUT',
          headers: {
            'api-key': process.env.NEXT_PUBLIC_MAEPL_API_KEY || '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: config.uuid,
            project_id: config.project_id,
            attribute_details: attributes,
          }),
          handleErrorTrackingSeverity(httpStatus) {
            return 'warn';
          },
        }
      )
  );
  return {
    addAttribute: call,
    addAttributeData: data,
    addAttributeError: error,
    addAttributeLoading: loading,
  };
}
