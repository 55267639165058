import amplitude from 'amplitude-js';
import { useEffect, useState } from 'react';
import { MaeplConfiguration, ObservationsInterface } from './types';
import { useGetResult } from './useGetResult';

export function useMaeplTreatment(
  config: MaeplConfiguration,
  treatmentGroup: string,
  observations: ObservationsInterface[],
  maeplProjectName: string
) {
  const { treatmentData, getTreatment, treatmentLoading, treatmentError } =
    useGetResult(config);
  const [treatment, setTreatment] = useState<string | undefined>();
  const [version, setVersion] = useState(0);

  useEffect(() => {
    const getExperiment = async () => {
      if (!treatmentData && !treatmentLoading && !treatmentError) {
        const getResultResponse = await getTreatment(observations);
        if (getResultResponse) {
          const treatment =
            getResultResponse.data?.data &&
            getResultResponse.data?.data[0].treatments;
          const requestedTreatment = treatment?.find(
            (treatment) => treatment.group === treatmentGroup
          )?.name;
          const version_id = treatment?.find(
            (treatment) => treatment.group === treatmentGroup
          )?.version_id;
          if (version_id) {
            setVersion(version_id);
          }
          if (requestedTreatment) {
            setTreatment(requestedTreatment);
            return;
          }
        }
        setTreatment('Control');
        return;
      }
    };
    getExperiment().catch((error) => {
      setTreatment('Control');
    });
  }, [
    treatmentGroup,
    observations,
    getTreatment,
    treatment,
    treatmentData,
    treatmentLoading,
    treatmentError,
  ]);

  useEffect(() => {
    if (treatment) {
      amplitude.getInstance().setUserProperties({
        [`MAEPL_ExperimentName_${maeplProjectName}`]: treatment,
        [`MAEPL_ExperimentVersion_${maeplProjectName}`]: version,
      });
    }
  }, [maeplProjectName, treatment, version]);

  return {
    treatment,
    version,
    treatmentLoading,
  };
}
