import {
  MaeplConfiguration,
  ObservationsInterface,
  useMaeplTreatment,
} from '@ee-monorepo/shared/data-access/maepl';
import { getCookie } from '@ee-monorepo/shared/utilities/functions';

export function usePrequalExperiment() {
  const config: MaeplConfiguration = {
    uuid: process.env.NEXT_PUBLIC_MAEPL_PREQUAL_UUID || '',
    project_id: process.env.NEXT_PUBLIC_MAEPL_PREQUAL_PROJECT_ID || '',
  };

  const observations: ObservationsInterface[] = [
    {
      id: getCookie('analytics_uuid'), // Using analytics_uuid as the id
      secondary_ids: {},
      data: {},
    },
  ];

  const { treatment, treatmentLoading } = useMaeplTreatment(
    config,
    'website_visit',
    observations,
    'PreQualSplit'
  );

  return {
    isPrequal: treatment === 'On',
    isHoldout: treatment === 'Holdout',
    treatmentLoading,
  };
}
