interface HeroStyle {
  heroContentParent: string;
  heroContent: string;
  heroTitle?: string;
  heroBodyContent: string;
  buttonParent?: string;
  applyButton: string;
  applyButtonMobileParent: string;
  prequalBulletParent?: string;
  prequalImageParent?: string;
  textBelowActionButtons?: string;
  capsuleGraphic?: string;
  capsuleGraphicMobile?: string;
}

interface GetStyleProps {
  button_fixed_position_on_mobile: boolean;
}

export const getStyle = (
  variation:
    | 'default-slice'
    | 'heroStaticImage'
    | 'heroPrequal'
    | 'heroStackVariant',
  props: GetStyleProps
): HeroStyle => {
  const styleObject = styles(props);
  return styleObject[variation] || styleObject['default-slice'];
};

const styles = ({
  button_fixed_position_on_mobile,
}: GetStyleProps): { [key: string]: HeroStyle } => {
  return {
    'default-slice': {
      heroContentParent: 'relative snap-container m-auto',
      heroContent: 'relative py-[2.5rem] lg:py-[6rem] z-10  max-w-[786px]',
      heroTitle: 'flex row mb-4 headingXL md:displayFont',
      heroBodyContent: 'max-w-[464px]',
      buttonParent: 'flex flex-col md:flex-row mt-6',
      applyButton: 'md:mr-4 hidden md:flex',
      applyButtonMobileParent: 'flex flex-col block md:hidden px-4 py-3',
      capsuleGraphic: 'hidden md:inline-block',
      capsuleGraphicMobile: 'inline-block md:hidden',
    },
    heroStaticImage: {
      heroContentParent: 'lg:relative snap-container lg:!pl-[16px] xl:!pl-[0]', // 16px padding kept for the 1024px(tab) view
      heroContent: 'relative py-[2.5rem] lg:py-[6rem] z-10  max-w-[786px]',
      heroTitle: 'flex row mb-4 headingXL md:displayFont',
      heroBodyContent: 'max-w-[180px] md:max-w-[464px] xxl:max-w-[658px]',
      buttonParent: 'flex flex-col md:flex-row mt-6',
      applyButton: 'md:mr-4 hidden md:flex',
      applyButtonMobileParent: 'flex flex-col block md:hidden px-4 py-3',
    },
    heroPrequal: {
      heroContentParent: 'relative snap-container m-auto',
      heroContent: 'relative py-[2.5rem] lg:py-[6rem] z-10  max-w-[786px]',
      heroTitle: 'flex row mb-4 headingXL md:displayFont',
      heroBodyContent: 'max-w-max xl:max-w-[504px]',
      buttonParent: 'flex flex-col md:flex-row mt-6',
      applyButton: 'md:mr-4 hidden md:flex',
      applyButtonMobileParent: 'flex flex-col block md:hidden px-4 py-3',
      prequalBulletParent: 'flex flex-row',
      prequalImageParent: 'flex justify-center',
    },
    heroStackVariant: {
      heroContentParent: 'relative snap-container m-auto',
      heroContent:
        'relative py-[2.5rem] lg:py-[6rem] z-10 xs:max-w-[100%] lg:max-w-[50%]',
      heroTitle: 'flex row mb-4 headingL md:headingXL',
      heroBodyContent: '',
      buttonParent: 'flex flex-col md:flex-row mt-6',
      applyButton: button_fixed_position_on_mobile
        ? 'md:mr-4 hidden md:flex xs:justify-center'
        : 'md:mr-4 flex xs:justify-center',
      applyButtonMobileParent: button_fixed_position_on_mobile
        ? 'flex flex-col block md:hidden px-4 py-3'
        : 'hidden',
      textBelowActionButtons: button_fixed_position_on_mobile
        ? 'bodySmallFont block mt-2 xs:text-center md:text-left [&>p]:mb-0'
        : 'bodySmallFont block mt-2 xs:text-center md:text-left',
    },
    // Add more variations as needed
  };
};
