interface GetStyleProps {
  is_center_image: boolean;
}

interface HeroImageStyle {
  heroStaticImageParent: string;
}

export const getStyle = (
  variation:
    | 'default-slice'
    | 'heroStaticImage'
    | 'heroPrequal'
    | 'heroStackVariant',
  props: GetStyleProps
): HeroImageStyle => {
  const styleObject = styles(props);
  return styleObject[variation] || styleObject['default-slice'];
};

const styles = (props: GetStyleProps): { [key: string]: HeroImageStyle } => {
  const { is_center_image } = props;
  return {
    'default-slice': { heroStaticImageParent: 'right-0' },
    heroStaticImage: {
      heroStaticImageParent:
        'right-0 absolute bottom-[2.5rem] md:bottom-[5rem] max-w-[35%] xs:max-w-[45%] sm:max-w-[40%] md:max-w-[35%] lg:max-w-[50%] xl:max-w-[580px]',
    },
    heroPrequal: { heroStaticImageParent: 'right-0' },
    heroStackVariant: {
      heroStaticImageParent: `right-0 xs:max-w-[100%] lg:max-w-[50%] text-center xs:static lg:absolute ${
        is_center_image
          ? 'bottom-[2.5rem] xs:pb-4 lg:pb-0'
          : 'bottom-0 md:bottom-0'
      }`,
    },
    // Add more variations as needed
  };
};
