import { useEffect, useState } from 'react';
import { useGetResult } from './useGetResult';
import { MaeplConfiguration, ObservationsInterface } from './types';

interface Treatments {
  [key: string]: string;
}

export function useMaeplTreatments(
  config: MaeplConfiguration,
  treatmentGroups: string[],
  observations: ObservationsInterface[]
) {
  const { treatmentData, getTreatment, treatmentLoading, treatmentError } =
    useGetResult(config);
  const [treatments, setTreatments] = useState<Treatments>({});

  useEffect(() => {
    const getExperiments = async () => {
      if (!treatmentData && !treatmentLoading && !treatmentError) {
        const getResultResponse = await getTreatment(observations);
        let requestedTreatments: Treatments = {};
        if (getResultResponse) {
          const treatments =
            getResultResponse.data?.data &&
            getResultResponse.data?.data[0].treatments;
          requestedTreatments = treatmentGroups.reduce(
            (prev, current) => ({
              ...prev,
              [current]:
                treatments?.find((treatment) => {
                  return treatment.group === current;
                })?.name || 'Control',
            }),
            {}
          );
        }
        setTreatments(requestedTreatments);
        return;
      }
    };
    getExperiments().catch((error) => {
      setTreatments({});
    });
  }, [
    treatmentGroups,
    observations,
    getTreatment,
    treatments,
    treatmentData,
    treatmentLoading,
    treatmentError,
  ]);
  return {
    treatments,
  };
}
