export const CapsuleGraphic = (
  {
    size,
    className,
  }: {
    size?: 'small' | 'normal';
    className?: string;
  } = { size: 'normal' }
) => {
  if (size === 'small') {
    return (
      <svg
        width="155"
        height="273"
        viewBox="0 0 155 273"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <rect
          x="154"
          y="1"
          width="271"
          height="153"
          rx="76.5"
          transform="rotate(90 154 1)"
          stroke="#95D0E1"
          strokeWidth="2"
        />
      </svg>
    );
  }
  return (
    <svg
      width="512"
      height="544"
      viewBox="0 0 512 544"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_19826_152232)">
        <rect
          x="510"
          y="18"
          width="1019"
          height="508"
          rx="254"
          transform="rotate(90 510 18)"
          stroke="#95D0E1"
          strokeWidth="4"
        />
      </g>
      <defs>
        <clipPath id="clip0_19826_152232">
          <rect width="512" height="544" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
